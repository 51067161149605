import { Injectable } from '@angular/core';
import Clarity from '@microsoft/clarity';

@Injectable({
  providedIn: 'root'
})
export class ClarityService {
  private isInitialized = false;

  constructor() {
    this.initClarity();
  }

  private initClarity(): void {
    try {
      Clarity.init('p8auumsp9f');
      this.isInitialized = true;
    } catch (error) {
      console.error('Erro ao inicializar Clarity:', error);
    }
  }

  public identify(
    customId: string,
    customSessionId?: string,
    customPageId?: string,
    friendlyName?: string
  ): void {
    if (!this.isInitialized) return;

    try {
      Clarity.identify(customId, customSessionId, customPageId, friendlyName);
    } catch (error) {
      console.error('Erro ao identificar usuário no Clarity:', error);
    }
  }

  public setTag(key: string, value: string | string[]): void {
    if (!this.isInitialized) return;

    try {
      Clarity.setTag(key, value);
    } catch (error) {
      console.error('Erro ao definir tag no Clarity:', error);
    }
  }

  public trackEvent(eventName: string): void {
    if (!this.isInitialized) return;

    try {
      Clarity.event(eventName);
    } catch (error) {
      console.error('Erro ao trackear evento no Clarity:', error);
    }
  }

  public setConsent(consent: boolean = true): void {
    if (!this.isInitialized) return;

    try {
      Clarity.consent(consent);
    } catch (error) {
      console.error('Erro ao definir consentimento no Clarity:', error);
    }
  }

  public upgradeSession(reason: string): void {
    if (!this.isInitialized) return;

    try {
      Clarity.upgrade(reason);
    } catch (error) {
      console.error('Erro ao fazer upgrade da sessão no Clarity:', error);
    }
  }
} 